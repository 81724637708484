import React, { useState, useEffect } from 'react'
import Web3 from 'web3'
import contractABI from './ABI.json'
import { FaTwitter, FaInstagram, FaDiscord } from 'react-icons/fa' // Import social icons
import Stepper from './Stepper' // Import the Stepper component
import MantleNetworkIcon from './mantle_network_icon.png.png' // Replace with the actual path to your Mantle Network icon image
import TradeOnLootexImage from './Button_Trade_On_Lootex_Light.png' // Replace with the actual path to your Mantle Network icon image

const maxSupply = 69 // Replace with the maximum supply of NFTs

const contractAddress = '0xc0c0Be8bC1eB075F80F8356f76cf2867490fCf51'
const ControlGalsPrice = 10000000000000000
const IMAGE_URL =
  'https://minter.mypinata.cloud/ipfs/QmZCocKLtbreTe6urMe7i6qSCpKpSDJ8mBLmgEHrc9atRM/1.png'

function App() {
  const [web3, setWeb3] = useState(null)
  const [contract, setContract] = useState(null)
  const [mintedNFTImageUrl, setMintedNFTImageUrl] = useState(null)
  const [numberOfTokens, setNumberOfTokens] = useState(1) // State to hold the selected number of tokens
  const [totalCost, setTotalCost] = useState(ControlGalsPrice)
  const [totalSupply, setTotalSupply] = useState(0) // State to hold the total supply
  const [error, setError] = useState(null)
  const [userAddress, setUserAddress] = useState(null)
  const [currentChainId, setCurrentChainId] = useState(null)

  useEffect(() => {
    // Calculate total cost when numberOfTokens changes
    const cost = calculateTotalCost(numberOfTokens)
    setTotalCost(cost)

    // Check if the user has MetaMask or any other Ethereum wallet installed
    if (window.ethereum) {
      const newWeb3 = new Web3(window.ethereum || 'http://localhost:8545')
      setWeb3(newWeb3)
      // Request account access if needed (for modern DApp browsers)
      window.ethereum.enable()

      // Get the user's Ethereum address (account)
      const address = window.ethereum.selectedAddress
      if (address) {
        setUserAddress(address)
      }

      // Initialize contract instance
      const newContract = new newWeb3.eth.Contract(contractABI, contractAddress)
      setContract(newContract)

      // Fetch the total supply from the contract
      getTotalSupply(newContract)
      // Listen for chain changes when the wallet is connected
      const handleChainChange = (chainId) => {
        setCurrentChainId(chainId)
      }

      // Check the initial network when the wallet is connected
      if (window.ethereum) {
        checkNetwork()
      }

      // Listen for network changes when the wallet is connected
      if (window.ethereum) {
        window.ethereum.on('chainChanged', handleChainChange)
        return () => {
          window.ethereum.removeListener('chainChanged', handleChainChange)
        }
      }
    } else {
      console.error(
        'Please install MetaMask or any other Ethereum wallet to use this app.',
      )
    }
  }, [numberOfTokens])
  // Function to calculate total cost based on number of tokens
  const calculateTotalCost = (tokens) => {
    return ControlGalsPrice * tokens
  }

  const checkNetwork = async () => {
    try {
      const chainId = await window.ethereum.request({ method: 'eth_chainId' })
      setCurrentChainId(chainId)
    } catch (error) {
      console.error('Error checking network:', error)
    }
  }

  const handleChainChange = (chainId) => {
    setCurrentChainId(chainId)

    console.log()
  }

  const switchNetwork = async () => {
    if (!window.ethereum) {
      console.error('MetaMask or any other Ethereum wallet is not installed.')
      return
    }

    try {
      // Switch to a custom EVM network (e.g., Binance Smart Chain Mainnet)
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x1388', // Binance Smart Chain Mainnet chainId
            chainName: 'Mantle Mainnet',
            nativeCurrency: {
              name: 'MNT',
              symbol: 'MNT',
              decimals: 18,
            },
            rpcUrls: ['https://rpc.mantle.xyz'], // Binance Smart Chain Mainnet RPC URL
            blockExplorerUrls: ['https://explorer.mantle.xyz/'], // Binance Smart Chain Block Explorer URL
          },
        ],
      })
    } catch (error) {
      console.error('Error switching Ethereum network:', error)
    }
  }
  // Function to fetch the total supply from the contract
  const getTotalSupply = async (contractInstance) => {
    try {
      const totalSupply = await contractInstance.methods.totalSupply().call()
      setTotalSupply(parseInt(totalSupply))
      console.log(parseInt(totalSupply))
    } catch (error) {
      console.error('Error fetching total supply:', error)
    }
  }

  // const connectWallet = async () => {
  //   if (window.ethereum) {
  //     try {
  //       const accounts = await window.ethereum.request({
  //         method: 'eth_requestAccounts',
  //       })
  //       console.log('Connected account:', accounts[0])
  //     } catch (error) {
  //       console.error('User rejected connection request:', error)
  //     }
  //   } else {
  //     console.error(
  //       'MetaMask is not installed. Please install it and try again.',
  //     )
  //   }
  // }

  const handleMint = async () => {
    if (!contract) {
      console.error('Contract not initialized.')
      return
    }

    const isCorrectChain = currentChainId === '0x1388' // Binance Smart Chain Mainnet chainId

    // Check if the user is on the correct chain (Binance Smart Chain Mainnet)
    if (!isCorrectChain) {
      console.error('Please switch to Binance Smart Chain to mint NFTs.')
      setError('Please switch to Mantle to mint NFTs.') // Set the error message

      return
    }

    try {
      // Replace numberOfTokens with the desired number of tokens to mint
      // const numberOfTokens = 1

      // Get the maximum number of tokens that can be minted in a single transaction
      const maxTokensPerPurchase = 20

      // Check if the user is the contract itself
      if (
        window.ethereum.selectedAddress.toLowerCase() ===
        contractAddress.toLowerCase()
      ) {
        console.error('Contract wallet disallowed.')
        setError('Contract wallet disallowed.') // Set the error message

        return
      }

      // Check if the sale is active
      const saleIsActive = await contract.methods.saleIsActive().call()
      if (!saleIsActive) {
        console.error('Sale must be active to mint ControlGals.')

        return
      }

      // Check the number of tokens being minted does not exceed the limit
      if (numberOfTokens > maxTokensPerPurchase) {
        console.error(`Can only mint ${maxTokensPerPurchase} tokens at a time.`)
        setError('Can only mint 20 tokens at a time.') // Set the error message

        return
      }

      // Check if the purchase would exceed the max supply of ControlGals
      const maxSupply = await contract.methods.MAX_ControlGalss().call()
      const totalSupply = await contract.methods.totalSupply().call()
      if (parseInt(totalSupply) + numberOfTokens > parseInt(maxSupply)) {
        console.error('Purchase would exceed the max supply of ControlGalss.')
        setError('Purchase would exceed the max supply of ControlGalss.') // Set the error message
        return
      }
      // Calculate the total cost of the minting transaction
      const totalCost = ControlGalsPrice * numberOfTokens

      // Check if the user has enough Ether for the transaction
      const userBalance = await web3.eth.getBalance(
        (await web3.eth.getAccounts())[0],
      )
      if (parseInt(userBalance) < totalCost) {
        console.log(userBalance)
        console.error('Ether value sent is not correct. Insufficient balance.')
        setError('Insufficient balance.') // Set the error message

        return
      }

      try {
        // Call your NFT contract's mintControlGals function here
        const tx = await contract.methods.mintControlGals(numberOfTokens).send({
          from: window.ethereum.selectedAddress,
          value: totalCost.toString(),
        })

        // Get the minted NFT token ID from the transaction receipt
        const tokenId = tx.events.Transfer.returnValues.tokenId

        // Replace this with the URL of your NFT image hosting
        const nftImageUrl = `https://minter.mypinata.cloud/ipfs/QmZCocKLtbreTe6urMe7i6qSCpKpSDJ8mBLmgEHrc9atRM/${tokenId}.png`

        // Set the minted NFT image URL in state to display in the UI
        setMintedNFTImageUrl(nftImageUrl)

        // Minting successful, handle any additional logic or feedback
        console.log('NFT minted successfully!')
      } catch (error) {
        console.error('Error minting NFT:', error)
      }

      // Minting successful, handle any additional logic or feedback

      setError(null) // Clear the error message when minting is successful

      console.log('NFT minted successfully!')
    } catch (error) {
      console.error('Error minting NFT:', error)
      setError('Error minting NFT. Please try again later.') // Set the error message
    }
  }

  return (
    <div>
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <h1
          style={{
            fontSize: '36px',
            fontWeight: 'bold',
            color: 'purple',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
          }}
        >
          Only on Mantle Network!
        </h1>
        <img
          src={MantleNetworkIcon} // Use the imported Mantle Network icon here
          alt="Mantle Network Icon"
          style={{ width: '35px', height: '35px', objectFit: 'cover' }}
        />
        <h2>Control Gals Collections</h2>

        <img
          src={IMAGE_URL}
          alt="Provided NFT"
          style={{ width: '300px', height: '300px', objectFit: 'cover' }}
        />
        <p>Current Supply: {totalSupply}/69</p>

        {/* Add the Lootex link */}
        <p>
          Check out the collection on{' '}
          <a
            href="https://alpha.lootex.io/collections/mnt%3A0xc0c0be8bc1eb075f80f8356f76cf2867490fcf51"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lootex
          </a>
          !
        </p>

        {parseInt(totalSupply) >= maxSupply ? (
          // Display "Sold Out" button if total supply reaches the maximum
          <button
            style={{
              margin: '20px',
              padding: '10px 20px',
              fontSize: '16px',
              background: 'red',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            disabled
          >
            Sold Out
          </button>
        ) : (
          // Display the mint button if not sold out
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <Stepper
                value={numberOfTokens}
                minValue={1}
                maxValue={20} // Replace with the maximum number of tokens that can be minted per transaction
                onChange={setNumberOfTokens}
              />
            </div>
            <div>
              <p>
                Total Cost:{' '}
                {web3 ? web3.utils.fromWei(totalCost.toString(), 'ether') : 0}{' '}
                MNT
              </p>
            </div>
            <button
              style={{
                margin: '20px',
                padding: '10px 20px',
                fontSize: '16px',
                background: 'lightgray', // Lighter color for the switch button
                color: '#000',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={switchNetwork}
            >
              Switch to Mantle
            </button>
            <button
              style={{
                margin: '20px',
                padding: '10px 20px',
                fontSize: '16px',
                background: currentChainId === '0x1388' ? '#00f' : 'lightgray',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={handleMint}
              disabled={!currentChainId || currentChainId !== '0x1388'}
            >
              Mint Now
            </button>
          </div>
        )}

        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <Stepper
            value={numberOfTokens}
            minValue={1}
            maxValue={20} // Replace with the maximum number of tokens that can be minted per transaction
            onChange={setNumberOfTokens}
          />
        </div>
        <div>
          <p>
            Total Cost:{' '}
            {web3 ? web3.utils.fromWei(totalCost.toString(), 'ether') : 0} MNT
          </p>
        </div>
        <button
          style={{
            margin: '20px',
            padding: '10px 20px',
            fontSize: '16px',
            background: 'lightgray', // Lighter color for the switch button
            color: '#000',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          onClick={switchNetwork}
        >
          Switch to Mantle
        </button>
        <button
          style={{
            margin: '20px',
            padding: '10px 20px',
            fontSize: '16px',
            background: currentChainId === '0x1388' ? '#00f' : 'lightgray', // Set green background for the correct chain
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          onClick={handleMint}
          disabled={!currentChainId || currentChainId !== '0x1388'} // Disable the button if not on the correct chain
        >
          Mint Now
        </button> */}
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <a
            href="https://alpha.lootex.io/collections/mnt%3A0xc0c0be8bc1eb075f80f8356f76cf2867490fcf51"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={TradeOnLootexImage} // Replace with the path to your Trade on Lootex button image
              alt="Trade on Lootex"
              style={{ width: '113px', height: '40px', objectFit: 'cover' }}
            />
          </a>
        </div>
        {/* Show user's Ethereum address */}
        {userAddress && (
          <p style={{ fontSize: '12px', color: '#888' }}>
            Your address detected: {userAddress}
          </p>
        )}
        {/* Show the error message if an error occurs */}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        {/* Social link icons */}
        <a href="https://twitter.com/your_twitter_handle">
          <FaTwitter style={{ fontSize: '24px', margin: '5px' }} />
        </a>
        <a href="https://instagram.com/your_instagram_handle">
          <FaInstagram style={{ fontSize: '24px', margin: '5px' }} />
        </a>
        <a href="https://discord.gg/your_discord_server">
          <FaDiscord style={{ fontSize: '24px', margin: '5px' }} />
        </a>
      </div>

      <div style={{ textAlign: 'center', marginTop: '20px', fontSize: '12px' }}>
        {/* Copyright information */}© 2023 ControlGals. All rights reserved.
      </div>
    </div>
  )
}

export default App
