// Stepper.js
import React from 'react'

const Stepper = ({ value, minValue, maxValue, onChange }) => {
  const handleIncrement = () => {
    if (value < maxValue) {
      onChange(value + 1)
    }
  }

  const handleDecrement = () => {
    if (value > minValue) {
      onChange(value - 1)
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <button onClick={handleDecrement} style={{ marginRight: '10px' }}>
        -
      </button>
      <span>{value}</span>
      <button onClick={handleIncrement} style={{ marginLeft: '10px' }}>
        +
      </button>
    </div>
  )
}

export default Stepper
